$gray-bg: #f7f7f7;
$gray-bg-border: #cdcdcd;

.gray-bg {
  background-color: $gray-bg;
  margin-bottom: -100px;

  .gung-filters-inner {
    border: 1px solid $gray-bg-border;
    padding: 15px;
    background-color: white;
  }

  .gung-filters {
    // margin-top: -15px;
    // padding-left: 0;
    // padding-right: 0;
    // padding-top: 15px;
    background-color: transparent;
  }

  div.border-gray,
  .product-list-search-group {
    border: 1px solid $gray-bg-border;
    background-color: white;
    padding: 15px;
  }

  app-product-list-by-top-products .product-list-search-group {
    background-color: white;
    padding: unset;
  }

  /*app-hl-display-order-list-wrapper .product-list-search-group {
    border: unset;
    background-color: unset;
    padding: unset;
  }*/

  app-hl-checkout-wrapper .product-list-search-group,
  lib-cart .product-list-search-group {
    border: unset;
    background-color: unset;
  }

  @media screen and (min-width: 992px) {
    .sticky.gung-filters {
      overflow: unset;
      position: unset;
    }
  }

  .sticky .gung-filters-inner {
    overflow: auto;
    min-height: calc(4 * #{$navbar-size} + #{$navbar-extra-menu-height});
  }
}
