/*
 * Override default Bootstrap functions here
 *
 * These are imported first, before the customer specific overrides are done in gung-b2b.
 * This is to ensure that the functions of bootstrap can be used when overriding default variables, such as colors.
*/

@import '../node_modules/bootstrap/scss/functions';

@function contrast-color($color) {

    @if (lightness($color) > 50) {
        @return #000; 
    } @else {
        @return #fff; 
    }
}