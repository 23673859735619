.logo .navbar-brand {
  display: inline-block;
  margin-right: 0;

  &:hover {
    border-bottom: unset;
  }

  & img {
    @if $gung-logo {
      // content: unset;
      display: block;
      // -moz-box-sizing: border-box;
      // box-sizing: border-box;
      // background: url($gung-logo) no-repeat;
      // background-size: contain;
      // margin: 0 10%;
      // height: 70px;
      content: url($gung-logo);
    }

    @else {
      img {
        // content: url($gung-login-logo);
        display: block !important;
        margin: auto;
      }
    }
  }
}

.navbar.gung-navbar {
  box-shadow: $gung-navbar-box-shadow;

  .btn {
    margin: 0px;
    text-transform: none;
  }

  .nav-tabs {

    .nav-link:hover,
    .nav-link:focus {
      margin-bottom: 0px;
    }
  }

  li.nav-item {
    color: $light;
  }

  .navbar-top-info {
    background-color: $gung-navbar-top-info-background-color;

    a {
      color: $gung-navbar-top-info-color;
    }
  }

  a {
    color: $gung-navbar-a-tag-color;
  }

  .gung-mega-menu {
    box-shadow: $gung-navbar-box-shadow;
  }
}


.navbar.gung-navbar,
.collapse.navbar-collapse {
  background-color: $gung-navbar-background-color;
}

.left-menu .nav-item {
  align-items: center;
  display: flex;
  text-align: center;

  .active {
    font-weight: bold;
  }
}


.nav-bar-item-text {
  &.left-menu {
    @media screen and (max-width: 768px) {
      display: block !important;
      position: relative;

      .navbar-nav {
        flex-direction: column !important;
        position: absolute;
        background-color: $gung-navbar-background-color;
        height: auto !important;
        top: 22px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

        a {
          color: $gung-navbar-a-tag-color;
        }
      }
    }
  }
}

.mat-sidenav {
  z-index: 1002 !important;
}

.gung-app-navbar {
  background-color: $gung-navbar-background-color;

  .s-menu {
    @if $enable-rounded ==true {
      border-radius: 5px;
    }

    @else {
      border-radius: 0;
    }
  }

  .menu-name {
    a {
      @if $enable-rounded ==true {
        border-radius: 5px;
      }

      @else {
        border-radius: 0;
      }
    }

  }
}

@if $enable-rounded ==true {
  .gung-app-navbar .navbar-search {
    .form-control {
      border-radius: 5px 0 0 5px;
    }

    .btn-outline-secondary {
      border-radius: 0px 5px 5px 0;
    }
  }
}
