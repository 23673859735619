.form-control {
  &.input-date {
    padding: $input-padding-y $input-padding-x;
  }

  background-color: $forms-bg-color;
}

.form-control:focus {
  --bs-body-bg: #{$forms-active-bg-color};
}

.lib-meta-select-input-sm {
  .form-control {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0rem;
    font-size: 0.7875rem;
    line-height: 1.5;
  }
}

.form-select:focus {
  box-shadow: $form-select-box-shadow;
}

.gung-select-input-sm {
  .form-control {
    @extend .form-control-sm;
  }
}

lib-gung-text-input.label-bold {
  label {
    font-weight: bold;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-control-sm,
.gung-select-input-sm .form-control {
  min-height: auto;
}