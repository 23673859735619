
ul {
   // background-color: $gung-background-contrast;
  //  padding: $gung-ol-ul-padding;
    list-style: $gung-ul-list-style;
   // background-color: $gung-background-contrast;
}

ol {
    background-color: $gung-background-contrast;
    padding: $gung-ol-ul-padding;
    list-style: $gung-ol-list-style;
}

ngb-carousel ol {
    background-color: unset;
}


li {
    padding: $gung-li-padding;
}

.nav-tabs {
    li.nav-item {
        border-bottom: none;
    }
}
