/**
* GungLogin
**/
.gung-login {
  
}

/**
* FashionLogin
**/
.login-gung-theme .container.fashion-login, .login-gung-theme .bg-container  {
  
  @if $gung-login-background == '' {
    background-color: #eee;
  } 
  @else {
    background-image: url($gung-login-background);
  }
  .login-container {
    background-color: #0c0c0c85;
    
    a.forgot-password-text,
    a.login-instead-text,
    label {
      color: $light;
    }

    input, textarea {
      color: #fff;
      background: transparent;
    }
  }

  & .login-logo {
    @if $gung-login-logo {
      content: unset;
      display: block;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: url($gung-login-logo) no-repeat;
      background-position: center;
      background-size: contain;
      // height: image-height($gung-login-logo);
      // width: image-width($gung-login-logo);
      margin: 0 10%;
      height: 70px;
    } @else {  
      img {
        // content: url($gung-login-logo);
        display: block !important;
        margin: auto;
        width: 100%;
      }
    }
  }

  /* FORGOT PASSWORD TITLE*/
  .forgot-password {
    background: unset;
    margin-top: 1rem;
    margin-bottom: 0;

    input {
      appearance: none;
      border: 0px;
      font-size: 16px;
      border-bottom: 1px solid #dddddd;
    }
  }

  lib-gung-tabset a.nav-link {
    font-weight: normal;
    &.active {
      font-weight: bold;
    }
  }

  lib-gung-tabset.login-tabs > ul {
    border-bottom: none;
    li {
      padding: 0;
      .nav-link {
        padding: 0.75rem 1rem;
        background-color: #000;
        border-bottom: 1px solid #dee2e6 !important;
      }
      .nav-link.active {
        background-color: transparent !important;
        border-top: 1px solid #dee2e6 !important;
        border-bottom: none !important;
      }
      &:first-child .nav-link {
        border-right: 1px solid #dee2e6 !important;
      }
      &:last-child .nav-link {
        border-right: 1px solid transparent !important;
      }
    }
  }

  lib-gung-tabset .tab-content {
    padding: 2rem!important;
    &.center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
}

.custom-login-input {
  appearance: none;
  border: 0px;
  font-size: 16px;
  color: #ffffff;
  background-color: #ffffff00;
  border-bottom: 1px solid #dddddd;
}

/**
* NavbarLogin
**/
.login-gung-theme .container.navbar-login {

}
