app-hl-display-slider-promotion {
  // .carousel-indicators li {
  //     width : 20px!important;
  //     height: 20px !important;
  //     border-radius: 50% !important
  // }

  .carousel-indicators li {
    // width : 20px!important;
    // background-color: black;
    // height: 20px !important;
    // border-radius: 50% !important
    width: 12px !important;
    height: 12px !important;
    margin: 0 8px;
    background: #fff;
    border: 3px solid #fff;
    -webkit-transform: none !important;
    transform: none !important;
    transition: background 0.25s ease-in-out;
    border-radius: 50% !important;
  }

  .carousel-indicators li.active {
    background: 0 0;
  }

  .carousel-inner,
  .carousel-inner .carousel-item,
  .carousel-inner .carousel-item .img-wrapper {
    height: 100%;
  }

  .carousel-control-prev-icon {
    background-image: url('/assets/slider-arrow.png');
    height: 40px;
    filter: invert(1);
    transform: rotate(180deg);
  }

  .carousel-control-next-icon {
    background-image: url('/assets/slider-arrow.png');
    height: 40px;
    filter: invert(1);
  }
}
