
.cursor-pointer {
  cursor: pointer;
}

.container-full mat-sidenav-content .wrapper-content-container div.container.main {
  max-width: unset;
}

.container.main {
  margin-bottom: $gung-container-margin-bottom;
  padding-top: $container-gutter;
  padding-bottom: $gung-container-padding-bottom;
}

.container {
  padding-left: $container-gutter;
  padding-right: $container-gutter;
  @extend .contrast-container;
}

.contrast-container {
  background-color: $gung-background-contrast;
}

.gung-mega-menu {
  .container {
    padding: 0;
  }
}

.cart-content.container {
  padding-top: 0;
}

.fixed-search {
  @extend .container;
  background: $gung-background-contrast;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  // This high z-index since dropdowns (as used in topfilters) have a z-index of 1000, causing them to be shown above
  // the search.
  z-index: 1001;
  position: sticky;
  top: 120px;
  margin-top: 0px;
  padding-right: 0px;
  width: 100%;


  &::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid $input-border-color;
  }
}

.gung-fixed-element {
  @include make-container();
  @extend .container;

  padding: 0;
  padding-top: $container-gutter;
  margin-top: -$container-gutter;
  margin-left: -$container-gutter;
  padding-left: $container-gutter;
  padding-right: $container-gutter;
  width: 100%;
  background: $gung-background-contrast;
  position: fixed;

  // This high z-index since dropdowns (as used in topfilters) have a z-index of 1000, causing them to be shown above
  // the search.
  z-index: 1001;
}

.gung-stepper {
  z-index: 9;

  &::after {
    border-bottom: 0px solid $input-border-color;
  }
}

// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    %responsive-search-#{$breakpoint} {
      max-width: $container-max-width;
    }

    @each $name, $width in $grid-breakpoints {
      @if ($container-max-width > $width or $breakpoint ==$name) {
        .fixed-search#{breakpoint-infix($name, $grid-breakpoints)} {
          @extend %responsive-search-#{$breakpoint};
        }
      }
    }
  }
}

a {
  text-decoration: none;
}


lib-edi-send-status {
  .ok {
    color: $success;
  }

  .attention {
    color: $warning;
  }

  .error {
    color: $danger;
  }
}

.modal-open .modal {
  z-index: 1055 !important;
}

.visually-hidden {
  display: none;
}

.accordion-header {
  .accordion-button {
    width: 100%;
    background: transparent;
    justify-content: space-between;
    display: flex;
    border: 0px;
    border-bottom: 1px solid #cecece;
    padding: 10px 5px;
    margin-bottom: 5px;
  }
}

.input-group-append {
  .input-group-text {
    height: 100%;
  }
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

.mx-auto,
.mr-auto {
  margin-right: auto;
}

.border-left {
	border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-right {
	border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.rounded-left {
	border-bottom-left-radius: var(--bs-border-radius) !important;
	border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-right {
	border-top-right-radius: var(--bs-border-radius) !important;
	border-bottom-right-radius: var(--bs-border-radius) !important;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-light {
  font-weight: lighter;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-style-italic {
  font-style: italic;
}

.font-style-normal {
  font-style: normal;
}
