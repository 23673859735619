.stepper-indicator.active {
  background-color: $gung-checkout-stepper-indicator-color !important;
  z-index: 1;
}

.stepper-indicator.active::before,
.stepper-indicator.active:last-child::after {
  background-color: $gung-checkout-stepper-indicator-color !important;
  // transform: translate(0px, 1px) scaleY(1.3) translateY(-50%) !important;
}

.stepper-indicator,
.stepper-indicator:before,
.stepper-indicator:last-child:after {
  background-color: $gung-checkout-stepper-color!important;
}

.gung-stepper {
  // @extend .gung-fixed-element;
  @extend .fixed-search;

  margin-top: -$container-gutter !important;
  top: 0 !important;

  .stepper-control-top {
    background-color: $gung-background-contrast;
    width: calc(100% - (2 * #{$container-gutter}));
    margin-left: $container-gutter;
  }

  .offset {
    display: none;
  }
}


.gung-stepper-indicator-offset {
    margin-top: 85px;
}


lib-delivery-date-picker.request-delivery-date .form-group {
  margin-bottom: unset!important;
}