.custom-slider-range {
  // range bar
  .ngx-slider .ngx-slider-bar {
    background: lighten($primary, 25%);
    height: 2px;
  }
  // selected range bar
  .ngx-slider .ngx-slider-selection {
    background: darken($primary, 5%);
  }
  // range selector
  .ngx-slider .ngx-slider-pointer {
    width: 16px;
    height: 16px;
    //top: auto; /* to remove the default positioning */
    top: -8px;
    bottom: 0;
    background-color: $primary;
    //border-top-left-radius: 3px;
    //border-top-right-radius: 3px;
  }

  .ngx-slider .ngx-slider-pointer:after {
    display: none;
  }

  .ngx-slider .ngx-slider-bubble {
    bottom: 14px;
  }

  // text limit in slide
  .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    color: $primary;
    display: none;
  }

  .ngx-slider .ngx-slider-model-value,
  .ngx-slider .ngx-slider-model-high,
  .ngx-slider .ngx-slider-combined {
    display: none;
  }

  .ngx-slider .ngx-slider-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: lighten($primary, 25%);
    top: -1px;
  }

  .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background: $primary;
  }
}