@import '../assets/font/dm-sans';

$primary: rgb(4, 178, 159) !default;
$secondary: #176480 !default;
$link-color: $secondary !default;
$light: #fafafa !default;

$bs-accordion-bg: #fff !default;
$bs-accordion-border-width: 0 !default;

$dark: #252525 !default;
$info: #333333 !default;

$danger: #b91313 !default;
$success: #1D7A0C !default;
$warning: #f1ce0e !default;

$font-weight-small: 400 !default;

$gung-background-contrast: #fff !default;
$gung-background-contrast-padding-y: 1.5rem;
$gung-background-contrast-padding-x: 1.5rem;
$body-bg: #ffffff !default;
$body-color: $dark !default;
$forms-bg-color: #fff !default;
$dropdown-bg-color: #fff !default;
$forms-active-bg-color: #fff !default;
$form-select-box-shadow: none !default;

$text-muted: #6c757d !default;

$gung-logo: false !default;

$gung-login-logo: $gung-logo !default;
$gung-login-background: "" !default;

//Global
$enable-rounded: false !default;
$link-hover-decoration: none !default;

//GRID/CONTAINER
$grid-gutter-width: 20px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1380px,
) !default;

$container-gutter: 30px !default;

$gung-container-margin-bottom: 3rem !default;
$gung-container-padding-bottom: 3rem !default;

//TEXT/FONT

$font-family-base: "DM Sans", sans-serif !default;
$font-family-sans-serif: "DM Sans", sans-serif !default;

$link-decoration: none !default;

$font-weight-base: 400 !default;
$font-size-base: 0.9rem !default;

$label-margin-bottom: 0 !default;
//Tables
$table-striped-order: odd !default;
//$table-bg: $gung-background-contrast !default;
//$table-accent-bg: $light !default;

$table-cell-padding-sm: 0.5rem 0.75rem 0.75rem !default;
$gung-button-in-table-margin-bottom: 0px !default;

//Navbar
$gung-navbar-background-color: $gung-background-contrast !default;
$gung-navbar-top-info-background-color: $primary !default;
$gung-navbar-top-info-color: $gung-background-contrast;
$gung-navbar-a-tag-color: $dark !default;
$gung-navbar-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);

//HTML LISTS ELEMENTS UL OL LI
$gung-li-padding: 0.2rem 0.5rem !default;
$gung-ol-list-style: decimal !default;
$gung-ul-list-style: circle !default;
$gung-ol-ul-padding: 2rem 3rem !default;

//Cards product card etc 
$card-bg: $gung-background-contrast !default;
$card-border-width: 0px !default;

$gung-card-margin-bottom: 1rem !default;
$gung-product-card-border: 1px solid rgba(0,0,0,.125) !default;

//Forms
$input-focus-border-color: #6c757d !default;

//Buttons
$input-btn-focus-box-shadow: none !default;
$gung-button-transform: none !default;
$gung-button-group-margin: 0 !default;
$gung-btn-text-transform: normal !default;
$btn-border-radius: 0px !default; 
$btn-box-shadow:  0 1px 3px 0 rgba(0,0,0,.2) !default;

$gung-btn-color: $body-color !default;
$gung-btn-primary-background: $primary !default;
$gung-btn-secondary-background: $secondary !default;

//Footer
$gung-footer-background-color: $primary !default;
$gung-footer-min-height: 20vh !default;
$gung-footer-display: none !default;
$gung-footer-logo-height: 60px !default;
$gung-footer-logo-margin-top: 3vh !default;

$gung-footer-content-container-margin-top: 20px !default;

//Checkut
$gung-checkout-stepper-indicator-color: $primary !default;
$gung-checkout-stepper-color: lighten($primary, 30%) !default;

// Filters
$gung-side-filter-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);

$input-border-color:  #ced4da;

$link-decoration:none !default;

$menu-links: $dark !default;
$menu-links-hover: $primary !default;

$menu-map: (
    'menu-links': $menu-links,
    'menu-links-hover': $menu-links-hover,
);


@import '../node_modules/bootstrap/scss/variables'; // Add default bootstrap variables last

// Map overrides
$theme-colors: map-merge($theme-colors, $menu-map);