.mr-1 {
  margin-right: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-10 {
  margin-left: 6rem !important;
}

.mr-10 {
  margin-right: 6rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pr-10 {
  padding-right: 6rem !important;
}

.pl-10 {
  padding-left: 6rem !important;
}

.text-muted {
    color: $text-muted !important;
}

.input-group-append{
  .input-group-text{
    height: 100%;
  }
}

.float-right {
  float: right;
}

.float-left{
  float: left;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.ml-auto {
  margin-left: auto;
}

.mx-auto {
  margin-right: auto;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    background-color: $value;
    color: color-contrast($value);
  }
}

@each $color, $value in $colors {
  .badge-#{$color} {
    background-color: $value;
    color: color-contrast($value);
  }
}

.border-left {
	border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-right {
	border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.rounded-left {
	border-bottom-left-radius: var(--bs-border-radius) !important;
	border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-right {
	border-top-right-radius: var(--bs-border-radius) !important;
	border-bottom-right-radius: var(--bs-border-radius) !important;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-light {
  font-weight: lighter;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-style-italic {
  font-style: italic;
}

.font-style-normal {
  font-style: normal;
}

.no-gutters {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@media (min-width: 576px) {
  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-left {
    text-align: left !important;
  }
}

@media (min-width: 768px) {
    .pl-md-1 {
        padding-left: .25rem !important;
    }

    .pr-md-1 {
        padding-right: .25rem !important;
    }

    .pl-md-2 {
        padding-left: .5rem !important;
    }

    .pr-md-2 {
        padding-right: .5rem !important;
    }

    .pl-md-3 {
        padding-left: 1rem !important;
    }

    .pr-md-3 {
        padding-right: 1rem !important;
    }

    .pl-md-4 {
        padding-left: 1.5rem !important;
    }

    .pr-md-4 {
        padding-right: 1.5rem !important;
    }

    .pl-md-5 {
        padding-left: 3rem !important;
    }

    .pr-md-5 {
        padding-right: 3rem !important;
    }

    .pl-md-10 {
        padding-left: 6rem !important;
    }

    .pr-md-10 {
        padding-right: 6rem !important;
    }

    .ml-md-1 {
        margin-left: .25rem !important;
    }

    .mr-md-1 {
        margin-right: .25rem !important;
    }

    .ml-md-2 {
        margin-left: .5rem !important;
    }

    .mr-md-2 {
        margin-right: .5rem !important;
    }

    .ml-md-3 {
        margin-left: 1rem !important;
    }

    .mr-md-3 {
        margin-right: 1rem !important;
    }

    .ml-md-4 {
        margin-left: 1.5rem !important;
    }

    .mr-md-4 {
        margin-right: 1.5rem !important;
    }

    .ml-md-5 {
        margin-left: 3rem !important;
    }

    .mr-md-5 {
        margin-right: 3rem !important;
    }

    .ml-md-10 {
        margin-left: 6rem !important;
    }

    .mr-md-10 {
        margin-right: 6rem !important;
    }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-left {
    text-align: left !important;
  }
}

@media (min-width: 992px) {
  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-left {
    text-align: left !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-left {
    text-align: left !important;
  }
}

@media (min-width: 1400px) {
  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-left {
    text-align: left !important;
  }
}

small, .small {
  font-weight: $font-weight-small;
}
