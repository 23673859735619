
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 1.5rem;
    font-weight: 600;
}

h1{
    text-transform: uppercase;
}

h3{
    margin-top: 0;
}

h4{
    text-transform: uppercase;
}

a {
    color: var(--bs-secondary);
    &:hover{
        text-shadow: none;
        border-bottom: 1px solid var(--bs-primary);
        color: var(--bs-primary);
        text-decoration: none;
        cursor: pointer;
    }
}


label {
    margin-bottom: $label-margin-bottom;
    font-size: 0.7rem !important;
    text-transform: uppercase;
}

.pre-line {
    white-space: pre-line;
}

.break-word {
    word-wrap: break-word;
}


.text-secondary{
    color: var(--bs-secondary) !important;
}