ngb-tabset li {
  border-bottom: 1px solid $dark;
}

ngb-tabset li > a {
  text-transform: uppercase;
  border: 0 !important;
  font-weight: 600;
}

ngb-tabset li > a.active {
  border-bottom: 5px solid $primary !important;
  margin-bottom: -3px;
}
