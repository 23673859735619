lib-image-carousel-minimal {
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
       filter: invert(1);
    }

    .carousel-inner {
        .carousel-item {
            // position: relative;
            // display: none;
            // -webkit-transition: 0.3s ease-in-out left;
            // -moz-transition: 0.3s ease-in-out left;
            // -o-transition: 0.3s ease-in-out left;
            // transition: 0.3s ease-in-out left;
            transition:all  0.2s;
        }
        .carousel-item.active {
        }
    }
}