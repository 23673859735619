footer.footer.gung-footer{
    background-color: $gung-footer-background-color;
    min-height: $gung-footer-min-height;
    display: $gung-footer-display;
    color: $light;
    text-align: center;

    .footer-logo {
        height: $gung-footer-logo-height;
        display: block;
        margin: auto;
        margin-top: $gung-footer-logo-margin-top;
    } 
    .footer-content-container  {
        margin-top: $gung-footer-content-container-margin-top;
    }

  
}
