// Import default functions from gung-theme (including bootstrap functions) to be able to use them in the custom variables.
@import '../../../node_modules/gung-theme/scss/functions';

// Import the customer specific variables
@import './style/variables';

@import './style/sizes';
// Import the default variables, mixins, and css from gung-theme. Lastly, it also imports the rest of bootstrap.
@import '../../../node_modules/gung-theme/scss/theme-with-bootstrap.scss';
// Import the customer specific css, this can override bootstrap classes as well
@import './style/custom';
@import './style/graybg';
@import './style/slider';

// Import font-awesome and flag-icons css classes
@import '../../../node_modules/flag-icons/css/flag-icons.min.css';
@import '../../../node_modules/@fortawesome/fontawesome-pro/css/all.min.css';

@import "../../../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css";
@import "../../../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css";