.card.product-card {
  margin-bottom: $gung-card-margin-bottom;
  border: $gung-product-card-border;
  /* box-shadow: 0 4px 6px rgba(0,0,0,0.15); */

  .product-card-container {
    height: 100%;
  }

  /* .image-wrapper:hover {
    opacity: 0.5;
    cursor: pointer;
  } */

  .product-description {
    position: absolute;
    visibility: hidden;
    padding-top: 10px;
    background-color: #FEFEFE;
  }
}

// TOP FILTERS
.filter-list {

  .dropup,
  .dropdown {
    margin-right: 10px;
  }

  .top-filter-dropdown {
    text-transform: none;
    border: 1px solid $input-border-color;

    &:hover, &:focus {
      border: 1px solid darken($input-border-color, 25%);
    }

  }
}

.top-filter-dropdown-list {
  width: max-content;

  .btn {
    text-transform: none;
  }
}


.filter-list {
  .gung-filters-inner {
    background-color: $gung-background-contrast;
    -webkit-box-shadow: $gung-side-filter-box-shadow;
    -moz-box-shadow: $gung-side-filter-box-shadow;
    box-shadow: $gung-side-filter-box-shadow;
    padding: 7px;
  }
}

.tab-content {
  .gung-filters-inner {
    li {
      border: none;
    }
  }
}

.flow-html{
  max-width: 100%;
  overflow: hidden;
  img{
    max-width: 100%;
  }
}
  
.selected-side-panel-filter {
  font-weight: bold;
  color: $secondary
}