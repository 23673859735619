.accordion{
  --bs-accordion-bg: $bs-accordion-bg;
  --bs-accordion-border-width:$bs-accordion-border-width;
  --bs-accordion-body-padding-y: 5px;
  --bs-accordion-body-padding-x: 5px;

  .accordion-header{
    .accordion-button{
      margin-bottom: 0px;
    }
  } 
}

.product-detail {
  .accordion {
    .card {
      margin-bottom: 0;
      &:hover {
        transform: none;
        box-shadow: none;
      }
    }

    .card:first-child .card-header {
      border-top: 1px solid #dddddd;
    }

    .card-header {
      background-color: #ffffff;
      border-bottom: 1px solid #dddddd;
      padding: 5px 5px 5px 10px;

      button {
        padding-left: 0;
        width: 100%;
      }
    }

    .card-body {
      padding: 10px;

      &:last-of-type {
        border-bottom: 1px solid #dddddd;
      }
    }

  }
}


.accordion-spacing {
  .card:not(:first-child) {
    margin-top: 0.4rem;
  }
}

.accordion.accordion-product-details .card {
  border: 0 !important;
  margin-bottom: 0;
  border: solid 1px #ddd !important;
  margin-top: -1px;
  padding: 5px 5px;
}

.accordion.accordion-product-details .card .card-header {
  padding: 0 !important;
  background-color: white;
}

.accordion.accordion-product-details .card .card-header .btn{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.accordion.accordion-product-details .card .card-body {
  padding: 0 !important;
}

