.modal{
  --bs-modal-bg: #fff;
}
.modal-lg-custom .modal-lg,
.modal-xl-custom .modal-xl {
  max-width: calc(100vw - 5%);
}

.modal.fade.show {
  z-index: 1060!important;
}

ngb-datepicker {
  z-index: 1061!important;
}

.modal-header{
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  .close{
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
  }
}

.modal-body {
  position: relative;
  padding: 1rem;
}