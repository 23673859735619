/* RIBBON FOR PRODUCT CARDS */

.ribbon {
	position: absolute;	
	left: 0px; // -10px SHOULD BE SET ON THE CUSTOMER CSS
	top: 0px; // 35px SHOULD BE SET ON THE CUSTOMER CSS
	padding: 0px 10px;
	color: white;
	min-width: 50%;
	font-size: 20px;
	text-align: center;
	text-transform: uppercase;
	z-index: 1;
	border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
	border-bottom-left-radius: 0px;
	border-top-left-radius: 3px;
}

.ribbon::before {
	display: block;
	width: 10px;
	height: 0px;
	position: absolute;
	bottom: -10px;
	left: 0;
	content: "";
	border-bottom: 10px solid transparent;
	border-right: 10px solid #002745;
}

.ribbon-small {
	left: -5px;
	min-width: 10%;
	max-width: 20%;
}

.ribbon-bottom::before {
	bottom: 28px;
	border-bottom: none;
	border-top: 10px solid transparent;
}

.ribbon-outlet {
	background-color: #EF6648;
}

.ribbon-outlet::before {
	border-right-color: #a0280e;
}

.ribbon-preorder {
	background-color: #2ecc71;
}

.ribbon-preorder::before {
	border-right-color: #27ae60;
}

.ribbon-news {
	background-color: #fbc531;
}

.ribbon-news::before {
	border-right-color: #e1b12c;
}

/* RIBBON ENDS HERE */