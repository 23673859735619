.card-assortment-tree.card-border-top {
  border-top: 6px solid $primary;
}

.card-assortment-tree .card-body {
  background-color: #f7f5f5;
}

.card-assortment-tree a:hover a {
  color: $primary;
}

.tooltip-container .tooltip-inner {
  background: unset;
  padding: 0;
}

.tooltip-container .arrow::before {
  background: unset;
  border-right-color: transparent;
}

.tooltip.show {
  opacity: 1;
}