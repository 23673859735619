$footer-background-color: #2d2d2d;
$footer-color: #ffffff;
$base-padding: 15px;
$bottom-margin: 10px;
$lightest-gray: #f1f1f1;
$lighter-gray: #cdcdcd;
$light-gray: #bdbdbd;

$navbar-size: 80px;
$brand-size: $navbar-size;
$navbar-top-image-size: $navbar-size;
$navbar-extra-menu-height: 45px;

$navbar-link: #8a8a8a;

$hl-blue: rgb(0, 109, 157);
$hl-blue-hover: lighten($hl-blue, 10%);
$hl-light-blue: rgb(0, 145, 210);
$hl-dark-blue: rgb(0, 75, 105);

$hl-gray: #3C3C3C;

$hl-white-tint: rgb(242, 242, 242);

$lib-gung-tab-blue: $hl-blue;
$lib-gung-tab-gray: $hl-white-tint;

// Breakpoints
$mobile: 576px;
$tablet: 768px;
$desktop: 992px;
$desktop-large: 1300px;

$custom-colors: (
  'primary': $hl-gray,
  'secondary': #b1b1b1,
  'dark': #3c3c3c,
  'text-gray': rgba(0, 0, 0, 0.6)
);
$primary: map-get($custom-colors, primary);
$secondary: map-get($custom-colors, secondary);

$enable-rounded: false;

$gung-checkout-stepper-indicator-color: #3c3c3c;
$gung-checkout-stepper-color: #b1b1b1;