.digital-assets-wrapper .margin-top-when-fixed-top-filters {
  margin-top: 10px!important;
}

.digital-assets-wrapper .fixed-search {
  margin-top: 0px;
  position: sticky;
  top: 110px;
  max-width: unset;
  width: unset;
  padding-right: 0;
  padding-top: 15px;
}

.digital-assets-modal {

  lib-gung-text-input-horizontal {
    .form-group {
      margin-bottom: 0;
    }
  
    label {
      display: none;
    }
  }
}

.product-export-assets {
  .page-title{
    display: none;
  }
}