//GLOBAL
.btn {
    text-transform: $gung-btn-text-transform;
    @if $enable-rounded {
        --bs-btn-border-radius: 0.375rem;
    } @else {
        --bs-btn-border-radius: 0;
    }

    &:hover {
        transform: $gung-button-transform;
    }
}

.btn-primary {
    background: $gung-btn-primary-background;
}

.btn-secondary {
    background:$gung-btn-secondary-background;
}

//Button which does not look like a button
.gung-disguised-button{
    border:none;
    background:none;
    &:hover,&:focus{
        border:none;
        background:none;
        outline: none;
    }
}

.btn-block{
    display: block;
    width: 100%;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

@media (max-width: 1200px) {
    .close {
        font-size: calc(1.26rem + .12vw);
    }
}

.close {
    float: right;
    font-size: 1.35rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.btn-link{
    text-decoration: none;
}