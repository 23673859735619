.ck.ck-balloon-panel {
  z-index: 100000!important;
}
/* 
.ck-content {
}
*/

lib-news-page-single,
lib-news-modal {
  div.ql-editor.ck-content.mw-100 img {
    max-width: 100%;
  }
}