.adyen-checkout__adyen-giving .adyen-checkout__status__icon {
  display: block;
  margin: var(--adyen-sdk-spacer-130, 56px) auto var(--adyen-sdk-spacer-100, 32px);
}
.adyen-checkout__adyen-giving .adyen-checkout__status__text {
  color: var(--adyen-sdk-color-label-primary, #00112c);
  margin-bottom: var(--adyen-sdk-spacer-130, 56px);
  text-align: center;
}
.adyen-checkout__adyen-giving .adyen-checkout-disclaimer__label {
  margin-top: 0;
  color: var(--adyen-sdk-color-label-primary, #00112c);
}

.adyen-checkout__campaign {
  position: relative;
}

.adyen-checkout__campaign-link:hover .adyen-checkout__campaign-description {
  text-decoration: underline;
}

.adyen-checkout__campaign-logo {
  border-radius: var(--adyen-sdk-border-radius-s, 4px);
  border: var(--adyen-sdk-border-width-s, 1px) solid var(--adyen-sdk-color-outline-primary, #dbdee2);
  display: block;
  flex-shrink: 0;
  height: var(--adyen-sdk-spacer-120, 48px);
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  width: var(--adyen-sdk-spacer-120, 48px);
}

.adyen-checkout__campaign-background-image {
  aspect-ratio: 5/2;
  background-color: var(--adyen-sdk-color-label-primary, #00112c);
  background-position: center;
  background-size: 100%;
  border-radius: var(--adyen-sdk-border-radius-m, 8px);
  margin-bottom: var(--adyen-sdk-spacer-060, 12px);
  overflow: hidden;
  transition: background-size 0.6s ease-out;
}
.adyen-checkout__campaign-background-image:hover {
  background-size: 110%;
}

.adyen-checkout__campaign-link {
  text-decoration: none;
}
.adyen-checkout__campaign-link:hover {
  text-decoration: underline;
}

.adyen-checkout__campaign-content {
  align-items: flex-start;
  display: flex;
  gap: var(--adyen-sdk-spacer-060, 12px);
}

.adyen-checkout__campaign-title,
.adyen-checkout__campaign-description {
  color: var(--adyen-sdk-color-label-primary, #00112c);
  margin: 0;
}

.adyen-checkout__campaign-title {
  font-size: var(--adyen-sdk-text-title-font-size, 16px);
  font-weight: var(--adyen-sdk-text-title-font-weight, 600);
  line-height: var(--adyen-sdk-text-title-line-height, 26px);
}

.adyen-checkout__campaign-cause {
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  font-weight: var(--adyen-sdk-text-caption-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  font-weight: var(--adyen-sdk-text-caption-stronger-font-weight, 500);
}

.adyen-checkout__campaign-description {
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  font-weight: var(--adyen-sdk-text-caption-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  margin-top: var(--adyen-sdk-spacer-060, 12px);
}

.adyen-checkout-roundup-description {
  font-size: var(--adyen-sdk-text-caption-font-size, 12px);
  font-weight: var(--adyen-sdk-text-caption-font-weight, 400);
  line-height: var(--adyen-sdk-text-caption-line-height, 18px);
  display: inline-block;
  color: var(--adyen-sdk-color-label-secondary, #5c687c);
  padding-left: 0;
}

.adyen-checkout__adyen-giving-actions {
  margin-top: var(--adyen-sdk-spacer-070, 16px);
  display: flex;
  flex-direction: column;
  gap: var(--adyen-sdk-spacer-070, 16px);
  text-align: center;
}

.adyen-checkout__button.adyen-checkout__button--donate {
  margin: var(--adyen-sdk-spacer-000, 0px);
}
.adyen-checkout__button.adyen-checkout__button--decline {
  display: block;
  margin: auto;
}